<script lang="ts" setup>
// todo: fix cookies layer
const { platform } = useAppConfig()
</script>

<template>
  <div bg-primary-500 dark:bg-neutral-100 h="188px sm:56px" w-full>
    <div fubex-container footer-security-text-color flex flex-col sm:flex-row-reverse items-center sm:justify-between gap-32px paragraph-md py="16px" w-full>
      <div flex flex-col sm:flex-row items-center gap-24px>
        <NuxtLink :to="`${platform.domain}/docs/gdpr.pdf`" :data-test="getCypressId('footer-gdpr')" target="_blank" basic-link>
          {{ $t('components.footer.gdpr') }}
        </NuxtLink>
        <NuxtLink :to="`${platform.domain}/docs/vop.pdf`" :data-test="getCypressId('footer-vop')" target="_blank" basic-link>
          {{ $t('components.footer.terms') }}
        </NuxtLink>
      </div>
      <div flex flex-col sm:flex-row items-center gap-4px>
        <p>{{ $t('components.footer.company') }}</p>
        <p>{{ $t('components.footer.rightsReserved') }} {{ new Date().getFullYear() }}</p>
      </div>
    </div>
  </div>
</template>
