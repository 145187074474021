<script lang="ts" setup>
const colorMode = useColorMode()

const darkMode = computed({
  get: () => colorMode.value === 'dark',
  set: (val: boolean) => colorMode.preference = val ? 'dark' : 'light',
})
</script>

<template>
  <ClientOnly>
    <HeadlessSwitchGroup :data-test="getCypressId('dark-mode-switch')" as="div">
      <HeadlessSwitch
        v-model="darkMode"
        bg-neutral-200
        relative inline-flex h-24px w-48px items-center rounded-full outline-none
        hover:bg-neutral-300
      >
        <span class="sr-only">{{ $t('components.toggleLightDarkMode') }}</span>
        <div
          dark:translate-x-26px translate-x-2px
          inline-block h-20px w-20px transform rounded-full bg-neutral-50 transition outline-none
          flex items-center justify-center text-neutral-600
        >
          <UnoIcon :class="darkMode ? 'i-fubex-moon' : 'i-fubex-sun'" w-12px h-12px opacity-70 />
        </div>
      </HeadlessSwitch>
    </HeadlessSwitchGroup>
  </ClientOnly>
</template>
