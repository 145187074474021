<script lang="ts" setup>
const { cookiesEnabledIds } = useCookieControl()
const { googleTagManagerId } = useRuntimeConfig().app

watch(cookiesEnabledIds, () => {
  if (cookiesEnabledIds.value?.includes('ga') ?? false)
    useScriptGoogleTagManager({
      id: googleTagManagerId,
    })
}, { immediate: true })
</script>

<template>
  <ClientOnly>
    <CookieControl locale="cs" />
  </ClientOnly>
</template>

<style>
/* .cookieControl__ModalContent {
  @apply fubex-rounded-lg overflow-hidden;
}

.cookieControl__ModalContentInner {
  @apply p-28px;
}

.cookieControl__ModalContent h3 {
  @apply text-22px;
}

.cookieControl__ModalContent a {
  @apply color-app-gold;
}

.cookieControl__ModalContent span {
  @apply text-14px!;
} */
.cookieControl__BarButtons button+button:last-child,.cookieControl__ControlButton {
  display: none;
}
.cookieControl__ModalButtons button:last-child {
  display: none;
}
</style>
