<script lang="ts" setup>
const { isLogged, isAdmin } = storeToRefs(useUser())
const { components: { navigation: { hidePages, hideSign } } } = useAppConfig()
</script>

<template>
  <div w-full bg-neutral-900 class="light">
    <div
      fubex-container flex items-center w-full text-neutral-50 px-16px
      flex-col
      sm="flex-row flex-nowrap  items-start justify-between" my="40px sm:54px"
    >
      <div
        w-full
        sm="basis-520px gap-8px"
      >
        <FooterLogo />
        <div
          mt-40px sm:mt-12px text-neutral-400
        >
          <FooterCompany />
        </div>
      </div>
      <div
        flex flex-col gap-40px
        sm="flex-row justify-between grow-1"
        lg="justify-end gap-80px"
        mt-40px sm:mt-16px
      >
        <div v-if="!hidePages" flex flex-col items-center sm:items-start gap-16px>
          <h3 heading6 font-700>
            {{ $t('components.footer.company') }}
          </h3>
          <I18nLink to="/o-nas" footer-link>
            {{ $t('components.footer.aboutUs') }}
          </I18nLink>
          <I18nLink to="/faq" footer-link>
            {{ $t('components.footer.faq') }}
          </I18nLink>
          <I18nLink to="/kontakt" footer-link>
            {{ $t('components.footer.contact') }}
          </I18nLink>
        </div>

        <div
          v-if="isLogged" flex flex-col items-center sm:items-start gap-16px
        >
          <h3 heading6 font-700>
            {{ $t('components.footer.myAccount') }}
          </h3>
          <I18nLink to="/profile" footer-link>
            {{ $t('components.footer.settings') }}
          </I18nLink>
          <I18nLink to="/profile/exchange" footer-link>
            {{ $t('components.footer.exchange') }}
          </I18nLink>
          <I18nLink to="/profile/history" footer-link>
            {{ $t('components.footer.exchangeHistory') }}
          </I18nLink>
          <I18nLink to="/logout" footer-link>
            {{ $t('components.footer.logout') }}
          </I18nLink>
          <I18nLink v-if="isAdmin" to="/profile/admin/bank-accounts" footer-link>
            {{ $t('components.footer.admin') }}
          </I18nLink>
        </div>
        <div v-else-if="!hideSign" flex flex-col items-center sm:items-start gap-16px paragraph-medium>
          <h3 heading6 font-700>
            {{ $t('components.footer.myAccount') }}
          </h3>
          <WidgetAuthDialogs default-view="login">
            <h4 footer-link>
              {{ $t('components.footer.login') }}
            </h4>
          </WidgetAuthDialogs>
          <WidgetAuthDialogs default-view="register">
            <h4 footer-link>
              {{ $t('components.footer.register') }}
            </h4>
          </WidgetAuthDialogs>
          <WidgetAuthDialogs default-view="forgottenPassword">
            <h4 footer-link>
              {{ $t('components.footer.forgotPassword') }}
            </h4>
          </WidgetAuthDialogs>
        </div>
      </div>
    </div>
  </div>
</template>
